<template>
  <div v-if="halls.length > 0" class="agenda-pills__wrapper">
    <div class="agenda-pills" :class="$isMobile && 'agenda-pills--mobile'">
      <div
        v-for="hall in halls"
        :key="hall.id"
        class="agenda-pills__title"
        :class="{
          'agenda-pills__title--active': currentHall.toLowerCase() === hall.name.toLowerCase(),
        }"
        :title="hall.name"
        @click="setCurrentId(hall.id, hall.name)"
      >
        <span v-line-clamp:18="2" class="agenda-pills__title-text">{{ hall.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { auth, schedule } from "@/store/modules/store.namespaces";
import { SET_AGENDA_PILLS_ID } from "@/store/modules/schedule/mutation-types";

export default {
  props: {
    halls: {
      type: Array,
      default: () => [],
    },
    currentHallId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentId: "",
      currentHall: "",
    };
  },
  computed: {
    ...mapState(auth, {
      scheduleMainFilter: state => state.user.scheduleMainFilter,
    }),
  },
  created() {
    this.setRightHall();
  },
  methods: {
    ...mapMutations(schedule, {
      setAgendaPillsId: SET_AGENDA_PILLS_ID,
    }),
    setCurrentId(id, name) {
      this.setAgendaPillsId(id);
      this.currentId = id;
      this.currentHall = name;
      this.$emit("setCurrentHall", id);
      localStorage.setItem("agentId", id);
    },
    setRightHall() {
      let hall;
      if (this.scheduleMainFilter) {
        hall = this.halls.find(item => item.name === this.scheduleMainFilter);
      }
      if (!hall) {
        hall = this.halls.find(item => item.id === this.currentHallId);
      }
      this.setCurrentId(hall.id, hall.name);
    },
  },
};
</script>

<style lang="less" scoped>
@import url(~@/styles/_mixin.less);

.agenda-pills {
  display: flex;
  max-width: 980px;
  padding: 0 0 8px 8px;
  font-size: 16px;
  color: white;
  overflow: hidden;
  overflow-x: auto;
  cursor: pointer;

  &--mobile {
    .reset-scroll();
  }

  &:after {
    display: block;
    width: 8px;
    min-width: 8px;
    height: 24px;
    content: "";
  }

  &__wrapper {
    margin: 0 -8px 8px;
    text-align: center;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 auto;
    min-width: 70px;
    max-width: 130px;
    padding: 2px 5px;
    font-size: 14px;
    color: var(--base-color);
    background: #fff;
    border-style: solid none solid solid;
    border-color: var(--base-color);
    border-width: 1px;
    transition: all 0.3s;
    user-select: none;

    &:first-child {
      border-radius: 8px 0 0 8px;
    }

    &:last-child {
      border-radius: 0 8px 8px 0;
      border-right: 1px solid var(--base-color);
    }

    &--active {
      color: white;
      background: var(--base-color);
    }

    &-text {
      word-break: break-word;
    }
  }
}
</style>
