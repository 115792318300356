<template>
  <div :class="inputClasses">
    <input
      v-if="type === 'input'"
      type="text"
      class="ws-input__inner"
      v-bind="$attrs"
      @input="onInputValueHandler"
    />
    <textarea
      v-if="type === 'textarea'"
      :value="text"
      v-bind="$attrs"
      class="ws-textarea__inner"
      rows="5"
      @input="onInputValueHandler"
    ></textarea>
    <span v-if="prefixIcon" class="ws-input__icon">
      <i class="el-input__icon" :class="prefixIcon"></i>
    </span>
    <div v-if="$slots.append" class="ws-input__append">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "WsInput",
  model: {
    prop: "text",
    event: "input",
  },
  props: {
    /**
     * v-model binding
     */
    text: String,
    prefixIcon: String,
    /**
     * Must be input or textarea
     */
    type: {
      type: String,
      default: "input",
    },
    invalid: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputClasses() {
      return [
        `ws-${this.type}`,
        {
          "ws-input--prefix": this.prefixIcon,
          "ws-input--invalid": this.invalid,
        },
      ];
    },
  },
  methods: {
    onInputValueHandler(event) {
      this.$emit("input", event.target.value);
    },
  },
};
</script>

<style scoped lang="less">
.ws-textarea,
.ws-input {
  position: relative;
  display: flex;
}

.ws-textarea__inner {
  display: block;
  width: 100%;
  height: 59px;
  padding: 19px 50px 0 10px;
  margin-right: 7px;
  overflow-y: auto;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  text-align: left;
  resize: none;
  outline: none;
}

.ws-input__inner {
  width: 100%;
  height: 36px;
  padding: 0 10px;
  font-size: 16px;
  border: 1px solid var(--base-color);
  border-radius: 4px;
  outline: none;
}

.ws-input--prefix {
  .ws-input__inner {
    padding: 0 35px 0 10px;
  }
}

.ws-input__icon {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  color: var(--base-color);
}

.ws-input__append {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  text-align: center;
  cursor: pointer;

  .ws-button {
    border-color: transparent;
  }
}

.ws-input--invalid .ws-input__inner {
  border-color: #f05735;
}
</style>
