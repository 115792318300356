<template>
  <form class="reward-form">
    <div v-if="!isSubmitted" class="reward-form__title">
      {{ $t("Agenda.ratingLimit") }}: {{ counterLimit }}
    </div>
    <div v-if="isSubmitted" class="reward-submitted">
      <p class="reward-submitted_title">{{ formSubmittedStatus }}</p>
    </div>

    <div v-else class="reward-form__content">
      <reward-user-list :users="users" />
      <reward-input
        :users="users"
        :rewardTypes="rewardTypes"
        @handleClick="handleClick"
        @handleGreeting="greetingHandler"
      />
    </div>

    <p class="reward-form__button">
      <reward-button
        v-if="!isSubmitted"
        :disabled="canSubmit"
        :style="{ alignSelf: 'center', marginTop: 'auto' }"
        @handleClick="submitForm"
      />
    </p>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { schedule } from "@/store/modules/store.namespaces";
import { GET_REWARDS_LIST, SEND_SETTED_REWARDS } from "@/store/modules/schedule/action-types";

import RewardUserList from "./RewardUserList";
import RewardInput from "./RewardInput";
import RewardButton from "./RewardButton";

export default {
  components: {
    RewardInput,
    RewardButton,
    RewardUserList,
  },
  props: ["eventId"],
  data() {
    return {
      submittedAnswers: [],
      formSubmittedStatus: "",
      counterLimit: 0,
    };
  },
  methods: {
    checkAnswers(userId, rewardId) {
      let result = -1;

      for (let i = 0; i < this.submittedAnswers.length; i++) {
        if (this.submittedAnswers[i][userId] && this.submittedAnswers[i][userId] === rewardId) {
          result = i;
        }
      }

      return result;
    },
    submitForm() {
      console.log("submit");
      const payload = {
        submittedAnswers: this.submittedAnswers,
        eventId: this.eventId,
      };

      this.setRewards(payload)
        .then(res => {
          this.formSubmittedStatus = res;
        })
        .catch(err => {
          this.formSubmittedStatus = err;
        });
    },
    handleClick(userId, rewardId, target) {
      const res = this.checkAnswers(userId, rewardId);
      const inputName = event.target.previousSibling.name;

      if (res !== -1) {
        this.submittedAnswers.splice(res, 1);
        if (inputName !== "Присутствие" && target !== "greeting") {
          this.counterLimit++;
        }
      } else {
        if (this.counterLimit <= 0 && inputName !== "Присутствие") {
          event.preventDefault();
          return;
        }
        this.submittedAnswers.push({ [userId]: rewardId });

        if (inputName !== "Присутствие" && target !== "greeting") {
          this.counterLimit--;
        }
      }
    },
    pressGreeting(item, rewardId) {
      const userID = item.id.split("_");
      this.handleClick(userID[0], rewardId, "greeting");
    },
    greetingHandler(rewardId) {
      const inputs = this.$el.querySelectorAll(".reward-form-radio_greeting:not(:checked)");

      if (!inputs.length) {
        const inputs = this.$el.querySelectorAll(".reward-form-radio_greeting");

        inputs.forEach(item => {
          item.checked = false;
          this.pressGreeting(item, rewardId);
        });

        return;
      }

      inputs.forEach(item => {
        item.checked = true;
        this.pressGreeting(item, rewardId);
      });
    },
    ...mapActions(schedule, {
      getRewardList: GET_REWARDS_LIST,
      setRewards: SEND_SETTED_REWARDS,
    }),
  },
  computed: {
    ...mapState(schedule, ["scheduleRewardList"]),
    ...mapState(schedule, {
      isSubmitted: "isRewardSet",
    }),
    rewardTypes() {
      return this.scheduleRewardList.Reward_types;
    },
    users() {
      return this.scheduleRewardList.Users;
    },
    canSubmit() {
      return Object.keys(this.submittedAnswers).length < 1;
    },
  },
  watch: {
    users() {
      this.counterLimit = Object.keys(this.users).length;
    },
  },
  created() {
    this.getRewardList();
  },
};
</script>

<style lang="less" scoped>
.reward-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 600px;
  padding: 30px 18px 12px 28px;

  &__title {
    width: 145px;
    font-size: 13px;
    line-height: 0.62;
    color: #414141;
    letter-spacing: normal;
  }

  &__content {
    display: flex;
    padding-bottom: 10px;
  }
}

.reward-submitted {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.reward-form__button {
  align-self: center;
  margin-top: auto;
}

@media (max-width: 768px) {
  .reward-form__button {
    position: fixed;
    bottom: 46px;
    align-self: center;
    margin: 0;
  }
}
</style>
