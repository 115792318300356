<template>
  <div class="reward-form-value">
    <div
      v-for="(reward, index) in rewardTypes"
      :key="reward.id"
      class="reward-form-headings"
    >
      <p class="reward-form-heading">{{ reward.name }}</p>

      <div v-for="user in users" :key="user.id" class="reward-form-group">
        <input
          :id="`${user.id}_${reward.id}`"
          class="reward-form-radio"
          type="checkbox"
          :name="reward.name"
          :class="{
            'reward-form-radio_greeting': reward.name === 'Присутствие',
          }"
        />
        <label
          class="icon-check icons reward-form-radio__label"
          :for="`${user.id}_${reward.id}`"
          @click="$emit('handleClick', user.id, reward.id)"
        ></label>
      </div>
      <button
        v-if="!index"
        type="button"
        class="reward-form__greeting-button"
        @click.prevent="$emit('handleGreeting', reward.id)"
      >
        {{ $t("Agenda.allWerePresent") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["users", "rewardTypes"],
};
</script>

<style lang="less" scoped>
.reward-form-group {
  margin-bottom: 16px;
  text-align: center;
}
.reward-form-value {
  display: flex;
  margin-bottom: 15px;
  padding-top: 10px;
}

.reward-form-input {
  display: flex;
  flex: 1;
  justify-content: space-around;
} //reward-form-input

.reward-form-radio {
  display: none;
  :checked + &__label {
    color: #7cde10;
  }
  :disabled + &__label {
    color: #d50000;
  }
  &__label {
    font-size: 16px;
    color: #bbbbbb;
    &:before {
      height: 25px;
      width: 25px;
      line-height: 25px;
    }
  }
} // radio-buttons

.reward-form-participants {
  display: flex;
  align-items: center;
  flex-basis: 40%;
  max-height: 25px;
  &__img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: lightcyan;
    overflow: hidden;
    margin-right: 8px;
    & > img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &__name {
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #000000;
  }
} // participants

// headings
.reward-form-headings {
  display: flex;
  width: 60%;
  min-width: 90px;
  flex-direction: column;

  &:first-child {
    position: relative;
    padding: 0 11px;
    box-sizing: content-box;
    text-align: center;

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: -59px;
      width: 11px;
      height: calc(115%);
    }

    &::before {
      left: 0;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.07),
        transparent
      );
    }

    &::after {
      right: 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.07),
        transparent
      );
    }
  }
}

.reward-form__greeting-button {
  position: absolute;
  bottom: -11px;
  left: 17px;
  width: 82px;
  padding: 6px 0 4px;
  font-size: 8px;
  line-height: 1;
  letter-spacing: -0.2px;
  text-align: center;
  color: #414141;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 9px;
  z-index: 1;
  outline: none;
  transition: 300ms;

  &:hover {
    border-color: var(--mute-text-color);
    color: var(--mute-text-color);
  }

  &:active {
    color: #414141;
    border: 1px solid #dddddd;
  }
}

.reward-form-heading {
  padding: 0 4px 0 4px;
  margin: 0;
  font-size: 10px;
  line-height: 0.8;
  color: #414141;
  margin-bottom: 5px;
  min-height: 42px;
}
// headings end
@media (max-width: 768px) {
  .reward-form__greeting-button {
    left: 15px;
  }
}
</style>
