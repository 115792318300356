<template>
  <div class="agenda-details" :class="{ opened: eventDetailOpened }" @click="openEventDetails">
    <div class="agenda-details__head">
      <div class="agenda-details__time">
        {{ serverDate }}
      </div>
      <i v-if="event.hasFiles" class="agenda-details__icon fal fa-paperclip"></i>
    </div>

    <div v-if="shouldShowSpeakerDetails" class="agenda-title">
      <p class="agenda-title__para">{{ event.eventName }}</p>
      <reward-button
        v-if="isShowRewardButton"
        :eventId="event.id"
        :style="{ marginTop: '10px' }"
        @handleClick="showRewardsForm"
      />
    </div>

    <div v-else-if="coffeeBreak" class="agenda-title">
      <p class="agenda-title__para"></p>
    </div>

    <div
      v-for="speaker in sortedSpeakers"
      :key="speaker.firstname"
      class="agenda-speaker"
      :class="{ closed: !getOpenedSpeaker || !event.id }"
      @click.stop="showSpeakerDetails(speaker)"
    >
      <div v-if="speaker.photo" class="agenda-speaker__img">
        <img class="agenda-speaker__img-img" :src="makeUrl(speaker.photo)" />
      </div>
      <div class="agenda-speaker__details">
        <p v-if="speaker.firstname" class="agenda-speaker__name">
          <span v-if="!speaker.photo" class="icon-user icons agenda-speaker__icon"></span>
          {{ speaker.firstname }}
        </p>
        <p v-if="speaker.position" class="agenda-speaker__title">
          {{ speaker.position }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations, mapState } from "vuex";
import RewardButton from "@/components/event/room/Agenda/components/RewardButton";
import { auth, room, schedule } from "@/store/modules/store.namespaces";
import { makeUrl } from "@/utils";
import { GET_REWARDS, SHOW_AGENDA_EVENT } from "@/store/modules/schedule/action-types";
import { SET_SELECTED_SCHEDULE_SPEAKER } from "@/store/modules/schedule/mutation-types";

export default {
  components: {
    RewardButton,
  },
  props: ["event", "coffeeBreak", "reward", "showRewards"],
  data() {
    return {
      isReward: false,
      isFav: false,
      openedSpeaker: 0,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(room, ["roomInfo"]),
    isUserCustomRoleRM() {
      return this.user.customRole === "rm";
    },
    isFilterSchedule() {
      const { scheduleMainFilter } = this.user;
      return (
        !scheduleMainFilter ||
        scheduleMainFilter?.toLowerCase() === this.event.scheduleName?.toLowerCase()
      );
    },
    isShowRewardButton() {
      return (
        this.isUserCustomRoleRM && this.event.rating && !this.isReward && this.isFilterSchedule
      );
    },
    shouldShowSpeakerDetails() {
      return !this.coffeeBreak && !this.reward;
    },
    serverDate() {
      return moment
        .unix(this.event.startTime)
        .utcOffset("+03:00")
        .format("HH:mm");
    },
    getOpenedSpeaker() {
      return this.roomInfo?.Speaker_in_agenda;
    },
    eventDetailOpened() {
      return this.roomInfo?.agenda_card;
    },

    /**
     * Сортировка спикеров по order.
     *
     * TODO: Временное решение, потом необходимо убрать.
     *
     * @returns {Array} arrSpeakers - возвращает массив отсортированных спикеров.
     */
    sortedSpeakers() {
      const arrSpeakers = this.event.speakers;
      arrSpeakers?.sort((a, b) => a.order - b.order);
      return arrSpeakers || [];
    },
  },
  watch: {
    event(newVal) {
      if (newVal && newVal.rating) {
        this.getRewards(this.event.id);
      }
    },
  },
  created() {
    if (this.event && this.event.rating) {
      this.getRewards(this.event.id);
    }
  },
  methods: {
    ...mapActions(schedule, {
      showAgendaEvent: SHOW_AGENDA_EVENT,
      getRewards: GET_REWARDS,
    }),
    ...mapMutations(schedule, {
      showScheduleSpeaker: SET_SELECTED_SCHEDULE_SPEAKER,
    }),
    openEventDetails(evt) {
      if (this.eventDetailOpened && !evt.target.classList.contains(`agenda-rewards`)) {
        this.showAgendaEvent(this.event.id);
      }
    },
    showRewardsForm() {
      this.$emit("showRewards", this.event);
    },
    /**
     * Открывает информацию о спикере если доступен модуль информации о спикере в настройках комнаты
     *
     * @param {object} speaker - информация о спикере
     */
    showSpeakerDetails(speaker) {
      if (this.getOpenedSpeaker) {
        this.showScheduleSpeaker(speaker);
      }
    },
    makeUrl(url) {
      return makeUrl(url);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_mixin");

.agenda-details {
  padding: 8px 10px 10px 7px;
  border-radius: 6px;
  background-color: #f5f5f5;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &.opened {
    cursor: pointer;
  }

  &__head {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-bottom: 9px;
  }

  &__time {
    display: inline-block;
    vertical-align: top;
    padding: 1px 10px 0;
    color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    background-color: var(--base-color);
    border-radius: 9px;

    &--local {
      background-color: var(--base-color-lighter);
    }

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__icon {
    display: block;
    font-size: 17px;
  }
}

.agenda-star {
  cursor: pointer;

  &:before {
    color: var(--base-color);
  }

  &--fav {
    background: rgb(202, 202, 22);
  }
}

.agenda-title {
  margin-bottom: 8px;

  &__para {
    margin: 0;
    padding: 0;
    overflow: hidden;
    overflow-wrap: break-word;
  }
}

.agenda-speaker {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &.closed {
    cursor: default;
  }

  &__img {
    width: 52px;
    height: 52px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0;
    position: relative;

    &-img {
      display: block;
      height: 100%;
      width: 100%;
      position: relative;
      .object-fit();
    }
  }
  &__icon {
    font-size: 12px;
    color: #838383;
    margin-right: 5px;
  }

  &__details {
    overflow: hidden;
    word-break: break-word;
    hyphens: auto;

    & > p {
      margin: 0;
      padding: 0;
    }
  }

  &__name {
    font-size: 16px;
    color: #353535;
    -webkit-hyphens: none;
  }

  &__title {
    font-size: 12px;
    color: #838383;
  }
}
</style>
