<template>
  <div class="agenda-panel">
    <div class="agenda-collapse">
      <div ref="agendaContent" class="agenda-content">
        <agenda-details
          v-for="event in computedEvents"
          :key="event.id"
          :event="event"
          :coffeeBreak="event.coffeeBreak"
          :reward="event.reward"
          @showRewards="$emit('show-rewards', event.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import AgendaDetails from "@/components/event/room/Agenda/components/AgendaDetails";
import { schedule } from "@/store/modules/store.namespaces";
import { GET_REWARDS } from "@/store/modules/schedule/action-types";

export default {
  components: {
    AgendaDetails,
  },
  props: [
    "schedule",
  ],
  data() {
    return {
      height: "",
      openIndex: "",
    };
  },
  computed: {
    computedEvents() {
      return this.schedule.events.map(event => {
        const obj = {};
        obj.id = event.id;
        obj.speakers = event.aux_info;
        obj.coffeeBreak = false;
        obj.reward = false;
        obj.rating = !!event.team_rating;
        obj.eventName = event.name;
        obj.scheduleName = this.schedule.name;
        obj.startTime = event.start_at_ts;
        obj.hasFiles = event.has_files;
        return obj;
      });
    },
    scheduleDate() {
      return new Date(this.schedule.date_utc);
    },
    isExpanded() {
      return this.id === this.openPanelIndex;
    },
  },
  mounted() {
    this.height = this.isExpanded ? this.$refs.agendaContent.clientHeight : 0;
  },
  methods: {
    ...mapActions(schedule, {
      getStatusOfRewardButton: GET_REWARDS,
    }),
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_mixin");

.agenda-button {
  .button-reset();
  font-size: 24px;
  font-weight: 300;
  color: #303030;
  width: 225px;
  text-align: left;
  position: relative;
  margin-bottom: 6px;
}

.agenda-icon {
  font-size: 12px;
  color: var(--base-color);
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;

  &--rotate {
    transform: translateY(-50%) rotate(90deg);
  }
}

.agenda-collapse {
  overflow: hidden;
  transition: height 0.3s; // animated
}
</style>
