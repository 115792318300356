<template>
  <div class="event-info-block">
    <back-button @go-back="closeDetailedEventInfo"></back-button>
    <div class="event-info">
      <div class="event-title">{{ event.name }}</div>
      <div class="event-dates">
        <div class="start">
          <span class="date">{{ formattedDate(event.start) }}</span
          >&nbsp;<span class="time">{{ formattedTime(event.start) }}</span>
        </div>
        <div v-if="event.end" class="end">
          <span class="date">&nbsp;-&nbsp;{{ formattedDate(event.end) }}</span
          >&nbsp;<span class="time">{{ formattedTime(event.end) }}</span>
        </div>
      </div>
      <div v-if="place" class="event-hall">{{ place }}</div>
      <div class="event-description">{{ event.value }}</div>
      <div class="event-speakers">
        <div
          v-for="(speaker, key) in event.speakers"
          :key="speaker.firstname"
          class="agenda-speaker"
          :class="{ opened: getOpenedSpeaker }"
          @click="showSpeakerDetails(speaker)"
        >
          <div v-if="speaker.photo" class="agenda-speaker__img">
            <img class="agenda-speaker__img-img" :src="makeUrl(speaker.photo)" />
          </div>
          <div class="agenda-speaker__details">
            <p v-if="speaker.fio" class="agenda-speaker__name">
              <i v-if="!speaker.photo" class="icon-user icons agenda-speaker__icon"></i>
              {{ speaker.fio }}
            </p>
            <p v-if="speaker.position" class="agenda-speaker__title">
              {{ speaker.position }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="links.length" class="event-links">
        <div class="title">{{ $t("common.links") }}</div>
        <div v-for="(link, i) in links" :key="i">
          <a class="link" :href="makeUrl(link.url)" target="_blank">
            {{ link.url }}
          </a>
          <div class="description">{{ link.text }}</div>
        </div>
      </div>
      <div v-if="event.files && event.files.length" class="event-files">
        <div class="title">{{ $t("common.files") }}</div>
        <div v-for="file in event.files" :key="file.id" class="file">
          <a :href="makeUrl(file.filepath)" target="_blank" download>
            {{ file.filename }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState } from "vuex";
import BackButton from "@/components/common/elements/BackButton";
import { makeUrl } from "@/utils";
import { room, schedule } from "@/store/modules/store.namespaces";
import {
  HIDE_AGENDA_EVENT_DETAILS,
  SET_SELECTED_SCHEDULE_SPEAKER,
} from "@/store/modules/schedule/mutation-types";

export default {
  name: "AgendaEventDetails",
  components: {
    BackButton,
  },
  props: ["event"],
  computed: {
    ...mapState(schedule, {
      selectedEventId: "shownAgendaEventId",
    }),
    ...mapState(room, ["roomInfo"]),
    getOpenedSpeaker() {
      const { roomInfo } = this;
      return roomInfo && roomInfo.Speaker_in_agenda;
    },
    place() {
      return this.event.aux_info.venue;
    },
    links() {
      return this.event.aux_info?.links || [];
    },
  },
  methods: {
    ...mapMutations(schedule, {
      showScheduleSpeaker: SET_SELECTED_SCHEDULE_SPEAKER,
      hideAgendaDetails: HIDE_AGENDA_EVENT_DETAILS,
    }),
    closeDetailedEventInfo() {
      this.hideAgendaDetails();
    },
    formattedDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    formattedTime(time) {
      return moment(time).format("HH:mm");
    },
    makeUrl(url) {
      return makeUrl(url);
    },
    /**
     * Открывает информацию о спикере если доступен модуль информации о спикере в настройках комнаты
     *
     * @param {object} speaker - информация о спикере
     */
    showSpeakerDetails(speaker) {
      if (this.getOpenedSpeaker) {
        this.showScheduleSpeaker(speaker);
      }
    },
  },
};
</script>

<style scoped lang="less">
@import url("~@/styles/_mixin");
.event-info-block {
  padding: 15px 20px;
  .event-info {
    padding: 15px 0;
    .event-title {
      font-size: 24px;
    }
    .event-dates {
      display: flex;
      color: var(--mute-text-color);
    }
    .event-hall {
      padding: 3px 0;
      color: var(--mute-text-color);
    }
    .event-description {
      padding: 15px 0;
      font-size: 18px;
      white-space: pre-wrap;
    }
    .event-links {
      padding-top: 15px;
      .title {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .link {
        color: var(--button-text-color);
        text-decoration: underline;
      }
      .description {
        color: var(--mute-text-color);
      }
    }
    .event-files {
      padding: 15px 0;
      .title {
        font-size: 18px;
        margin-bottom: 5px;
      }
      .file {
        a {
          color: var(--button-text-color);
          text-decoration: underline;
        }
      }
    }
    .event-speakers {
      .agenda-speaker {
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-bottom: 5px;
        }
        &.opened {
          cursor: pointer;
        }
      }

      .agenda-speaker__img {
        width: 52px;
        height: 52px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 8px;
        flex-shrink: 0;
        position: relative;

        &-img {
          display: block;
          height: 100%;
          width: 100%;
          position: relative;
          .object-fit();
        }
      }

      .agenda-speaker__icon {
        font-size: 12px;
        color: #838383;
        margin-right: 5px;
      }

      .agenda-speaker__details {
        overflow: hidden;
        word-break: break-word;
        hyphens: auto;

        & > p {
          margin: 0;
          padding: 0;
        }
      }

      .agenda-speaker__name {
        font-size: 16px;
        color: #353535;
        -webkit-hyphens: none;
      }

      .agenda-speaker__title {
        font-size: 12px;
        color: #838383;
      }
    }
  }
}
</style>
