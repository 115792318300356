<template>
  <transition v-if="isVisible" name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="reward">
          <span
            class="icon-close icons reward-icon"
            @click="$emit('show-rewards', eventId)"
          ></span>
          <reward-form :eventId="eventId" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import RewardForm from "@/components/event/room/Agenda/components/RewardForm";

export default {
  components: {
    RewardForm,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="less" scoped>
.reward {
  position: relative;
  min-height: 100%;
  &-icon {
    position: absolute;
    font-size: 22px;
    color: var(--base-color);
    right: 18px;
    margin-top: 10px;
  }
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: var(--base-fade-color);
  display: flex;
  transition: opacity 0.3s ease;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  overflow: auto;
  border-radius: 11px;
  width: 736px;
  height: 600px;
  max-height: 100%;
  border: solid 1px var(--base-color);
  background-color: #ffffff;
}

@media (max-width: 500px) {
  .modal-mask {
    padding: 5% 10px;
  }
}

@media (max-width: 768px) {
  .modal-mask {
    padding: 10% 10px;
  }

  .reward-icon {
    position: fixed;
  }
}

@media (max-width: 1000px) {
  .modal-mask {
    padding: 5% 10px;
  }

  .modal-wrapper {
    height: 100%;
  }
}
</style>
