<template>
  <flex-column-stack v-if="!loading" class="agenda__container" :class="{ loader: loading }">
    <div v-show="selectedScheduleSpeaker" class="agenda__back">
      <ws-button color="primary" text @click="resetSelectedSpeaker">
        <ws-icon light>arrow-left</ws-icon>
        {{ $t("common.back") }}
      </ws-button>
    </div>
    <loader v-if="loading" />
    <rewards-modal
      :is-visible="isShowingRewards.visible"
      :event-id="isShowingRewards.eventId"
      @show-rewards="showRewards"
    />
    <div v-if="!loading" v-show="!selectedScheduleSpeaker && !detailedEventInfo" class="agenda">
      <agenda-pills
        v-if="halls.length > 1"
        :halls="halls"
        :current-hall-id="currentHallId"
        @setCurrentHall="setCurrentHall"
        @setFilter="setFilter"
      />
      <div class="agenda-dates">
        <span
          v-for="date in agendaDates"
          :key="date.id"
          class="agenda-date"
          :class="{ 'agenda-date--active': date.id === currentDateId }"
          @click.prevent="setCurrentDateId(date.id)"
        >
          {{ formatDate(date.date) }}
        </span>
      </div>

      <!-- Форма очистки бало команды -->
      <div v-if="isShowClearForm">
        <form class="clear_command_form">
          <h4>{{ $t("Agenda.clearFormTitle") }}</h4>
          <ws-input
            v-model.trim="clearCommandForm.managerLogin"
            :placeholder="$t('Agenda.managerLoginPlaceholder')"
          />

          <ws-select
            v-model="clearCommandForm.event"
            label="name"
            :options="schedules[0] && schedules[0].events"
          />

          <ws-button
            color="accent"
            class="clear_command_button"
            :disabled="!isValidClearForm"
            @click="showClearModal = true"
          >
            {{ $t("Agenda.managerClearButton") }}
          </ws-button>
        </form>

        <smart-modal
          v-if="showClearModal"
          :textConfirm="$t('Agenda.clearFormApprove')"
          @confirm="clearRewards"
          @cancel="showClearModal = false"
        >
          <template slot="header">
            <h4>{{ $t("Agenda.clearFormConfirmation") }}</h4>
          </template>
        </smart-modal>
      </div>
      <!-- Форма очистки бало команды END-->

      <agenda-panel
        v-for="schedule in schedules"
        :id="schedule.id"
        :key="schedule.id"
        :schedule="schedule"
        :openPanelIndex="openPanelIndex"
        @showPanel="showSinglePanel"
        @show-rewards="showRewards"
      />
    </div>
    <user-card
      v-if="selectedScheduleSpeaker"
      full-info
      :user="speakerInfo"
      class="agenda__user-card"
    />
    <event-details
      v-if="detailedEventInfo && !selectedScheduleSpeaker"
      :event="detailedEventInfo"
    ></event-details>
  </flex-column-stack>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";

import AgendaPanel from "@/components/event/room/Agenda/components/AgendaPanel";
import AgendaPills from "@/components/event/room/Agenda/components/AgendaPills";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import { auth, notifications, room, schedule } from "@/store/modules/store.namespaces";
import { GET_SCHEDULE } from "@/store/modules/schedule/action-types";
import {
  HIDE_AGENDA_EVENT_DETAILS,
  HIDE_SCHEDULE_SPEAKER,
} from "@/store/modules/schedule/mutation-types";
import { getLocale } from "@/utils/i18n-setup";
import RewardsModal from "@/components/event/room/Agenda/components/RewardsModal";
import UserCard from "@/components/common/user/UserCard";
import { makeUrl, stringToHtmlLink } from "@/utils";
import WsIcon from "@/components/base/WsIcon";
import WsButton from "@/components/base/WsButton";
import WsSelect from "@/components/base/WsSelect";
import WsInput from "@/components/chat/components/Input";
import SmartModal from "@/components/common/modals/SmartModal";
import axios from "axios";
import config from "@/settings/config";
import AuthService from "@/services/AuthService";
import Loader from "../../../common/elements/Loader";
import EventDetails from "./components/AgendaEventDetails";

/**
 * Компонент страницы расписания
 *
 * @vue-computed {object} speakerInfo - подготовленная информация о спикере для передачи в карточку
 */
export default {
  name: "Agenda",
  components: {
    FlexColumnStack,
    AgendaPanel,
    AgendaPills,
    Loader,
    EventDetails,
    RewardsModal,
    UserCard,
    WsIcon,
    WsButton,
    WsSelect,
    WsInput,
    SmartModal,
  },
  data() {
    return {
      currentHallId: null,
      currentIdFilter: "",
      currentDateId: "",
      openIndex: "",
      halls: [],
      hallId: "",
      ready: false,
      showWarning: false,
      isShowingRewards: {
        visible: false,
        eventId: null,
      },
      clearCommandForm: {
        managerLogin: "",
        event: {},
      },
      showClearModal: false,
    };
  },
  computed: {
    ...mapState(auth, ["user"]),
    ...mapState(schedule, {
      schedule: "schedule",
      detailedEventInfo: "shownAgendaEvent",
      loading: "agendaLoadingStatus",
      selectedScheduleSpeaker: "selectedScheduleSpeaker",
    }),
    ...mapState(room, {
      timestamp: state => state.clock.timestamp || 0,
      locale: "interfaceLanguage",
      room: "roomNumber",
    }),
    ...mapState(notifications, ["lastNotification"]),
    agendaDates() {
      if (!this.ready) {
        return [];
      }
      const dateItems = [];

      const { schedule } = this;
      if (schedule) {
        const { halls } = schedule;
        if (halls && halls.length) {
          let currentHallId = parseInt(this.currentHallId || 0, 10);
          if (Number.isNaN(currentHallId)) {
            currentHallId = 0;
          }
          const relatedHalls = schedule.halls.filter(({ id }) => {
            return parseInt(id, 10) === currentHallId;
          });

          if (relatedHalls && relatedHalls.length && relatedHalls[0].data) {
            relatedHalls[0].data.forEach(({ date_utc, id }) => {
              dateItems.push({
                date: date_utc,
                id,
              });
            });
            dateItems.sort((a, b) => new Date(a.date) - new Date(b.date));
          }
        }
      }
      return dateItems;
    },
    // eslint-disable-next-line consistent-return
    schedules() {
      if (!this.ready) {
        return [];
      }
      const schedule = cloneDeep(this.schedule);
      let schedules = {};
      if (schedule.halls && schedule.halls.length) {
        schedules = schedule.halls.filter(hall => {
          return hall.id === this.currentHallId;
        });
        if (schedules[0]) {
          if (schedules[0].data) {
            schedules = schedules[0].data.filter(filterdSchedules => {
              filterdSchedules.name = schedules[0].name;
              return filterdSchedules.id === this.currentDateId;
            });
          }
          if (this.currentIdFilter) {
            schedules[0].events = schedules[0].events.filter(event => {
              return event.other_filter === this.currentIdFilter;
            });
          }
        }
        return schedules;
      }
      return [];
    },
    openPanelIndex() {
      return this.openIndex;
    },
    speakerInfo() {
      return this.selectedScheduleSpeaker
        ? {
            name: this.selectedScheduleSpeaker.firstname
              ? [this.selectedScheduleSpeaker.firstname]
              : [],
            avatar: this.selectedScheduleSpeaker.photo
              ? makeUrl(this.selectedScheduleSpeaker.photo)
              : null,
            location: this.selectedScheduleSpeaker.city,
            description:
              (this.selectedScheduleSpeaker.description &&
                stringToHtmlLink(this.selectedScheduleSpeaker.description)) ||
              "",
            position: this.selectedScheduleSpeaker.position
              ? [this.selectedScheduleSpeaker.position]
              : [],
            photo: this.selectedScheduleSpeaker.photo,
          }
        : {};
    },
    /**
     * Проверка на нашего суперадмина.
     *
     * @returns {boolean} Является ли пользователь нашим суперадмином.
     */
    isShowClearForm() {
      return (
        this.user?.role === 0 &&
        !this.user?.customRole &&
        this.user?.fields?.company === "WhenSpeak"
      );
    },
    /**
     * Простая валидация формы.
     *
     * @returns {boolean} Является ли форма валидной
     */
    isValidClearForm() {
      return (
        this.clearCommandForm?.managerLogin && Object.keys(this.clearCommandForm?.event)?.length > 0
      );
    },
  },
  watch: {
    room() {
      this.halls = [];
      this.currentHallId = null;
      this.currentDateId = "";
      this.makeAgenda();
    },
    locale() {
      this.halls = [];
      this.currentHallId = null;
      this.currentDateId = "";
      this.makeAgenda();
    },
  },
  created() {
    this.makeAgenda();
    const storageItemName = this.user.email ? `warningClosed${this.user.id}` : "warningClosedGuest";
    this.showWarning = !localStorage.getItem(storageItemName);
  },
  mounted() {
    try {
      this.$nextTick(() => {
        window.dispatchEvent(new Event("resize"));
      });
    } catch (e) {
      console.log(e);
    }
  },
  beforeDestroy() {
    this.hideScheduleSpeaker();
    this.hideAgendaDetails();
  },
  methods: {
    /**
     * Метод очистки баллов команды.
     */
    clearRewards() {
      const inner_id = this.clearCommandForm?.event?.id;
      const rm_login = this.clearCommandForm?.managerLogin;

      inner_id &&
        rm_login &&
        axios
          .post(`${config.serverUrl}/web/api/clearEventRewards`, null, {
            headers: {
              "x-token": AuthService.getToken(),
            },
            params: {
              inner_id,
              rm_login,
            },
          })
          .then(() => {
            this.showClearModal = false;
          })
          .catch(res => {
            this.$notify({
              group: "roomSystem",
              type: "error",
              duration: 5000,
              title: this.$t("common.error"),
              text: res?.response?.data?.message,
            });
            console.warn(res);
          });
    },
    showRewards(id) {
      this.isShowingRewards.visible = !this.isShowingRewards.visible;
      this.isShowingRewards.eventId = id;
    },
    // eslint-disable-next-line consistent-return
    findHallIndexById(hallId) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0, leng = this.halls.length; index < leng; index++) {
        if (this.halls[index].id === hallId) return index;
      }
    },
    closestToCurrentDateId(hall) {
      const time = new Date(this.timestamp * 1000).setHours(0, 0, 0);
      if (hall.data && hall.data.length > 0 && time) {
        let closest = null;
        let minDiff = null;
        // eslint-disable-next-line no-plusplus
        for (let index = 0, leng = hall.data.length; index < leng; index++) {
          const dist = new Date(hall.data[index].date_utc).getTime() - time;
          if (minDiff || minDiff === 0) {
            if (Math.abs(minDiff) > Math.abs(dist) && minDiff !== 0) {
              closest = index;
              minDiff = dist;
            }
          } else {
            closest = index;
            minDiff = dist;
          }
        }
        if (minDiff < 0) {
          if (hall.data[closest + 1]) {
            return hall.data[closest + 1].id;
          }
          return hall.data[closest].id;
        }
        return hall.data[closest].id;
      }
      return null;
    },
    // eslint-disable-next-line consistent-return
    filterToGetCurrentDate() {
      const schedule = cloneDeep(this.schedule);
      let schedules = {};
      if (schedule.halls && schedule.halls.length > 0) {
        schedules = schedule.halls.filter(hall => {
          return hall.id === this.currentHallId;
        });
        if (this.currentIdFilter) {
          schedules[0].data[0].events = schedules[0].data[0].events.filter(event => {
            return event.other_filter === this.currentIdFilter;
          });
        }
        return schedules;
      }
    },
    findNearestDate([schedules], defaultId) {
      if (!schedules || !schedules.data.length) return defaultId;
      if (schedules.data.filter(date => date?.id === this.currentDateId).length) {
        return this.currentDateId;
      }
      const today = this.$moment().format("YYYY-MM-DD");
      schedules.data.sort((a, b) => {
        return (
          Number(this.$moment(a.date_utc).format("x")) -
          Number(this.$moment(b.date_utc).format("x"))
        );
      });
      let nearestDate = schedules.data.find(item => {
        const date = this.$moment(item.date_utc).format("YYYY-MM-DD");
        return this.$moment(date).isSameOrAfter(today);
      });

      if (!nearestDate) {
        nearestDate = schedules.data.reverse().find(item => {
          const date = this.$moment(item.date_utc).format("YYYY-MM-DD");
          return this.$moment(date).isSameOrBefore(today);
        });
      }

      return nearestDate.id || defaultId;
    },
    setCurrentHall(hallId) {
      if (this.currentHallId !== hallId) {
        this.currentHallId = hallId;
        const filteredSchedules = this.filterToGetCurrentDate();
        const currentHall = this.halls[this.findHallIndexById(hallId)];
        if (currentHall) {
          this.currentDateId = this.findNearestDate(
            filteredSchedules,
            currentHall.defaultHighlightedId,
          );
        }
      }
    },
    setFilter(val) {
      if (this.currentIdFilter !== val) {
        this.currentIdFilter = val;
      }
    },
    setCurrentDateId(dateId) {
      this.currentDateId = dateId;
    },
    showSinglePanel(id) {
      this.openIndex = id;
    },
    makeAgenda() {
      this.halls = [];
      this.getScheduleByHalls().then(() => {
        const halls = [];
        let schedules = [];
        const schedule = { ...this.schedule };
        if (schedule.halls && schedule.halls.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < schedule.halls.length; i++) {
            const obj = {};
            obj.id = schedule.halls[i].id;
            obj.name = schedule.halls[i].name;
            obj.defaultHighlightedId = this.closestToCurrentDateId(schedule.halls[i]);
            halls.push(obj);
          }
          if (!this.currentHallId || this.halls.every(hall => hall.id !== this.currentHallId)) {
            this.currentHallId = halls[0].id;
          }
          schedules = this.filterToGetCurrentDate();

          // TODO костыль для комнаты 4634, убрать после реализации порядка залов в расписании
          if (["4634"].indexOf(this.room) === -1) {
            this.halls = Object.assign([], halls);
          } else {
            this.halls = Object.assign(
              [],
              halls.sort((a, b) => {
                let aWeight = 0;
                let bWeight = 0;
                if (a.name === "Общее") {
                  aWeight = 100;
                }
                if (b.name === "Общее") {
                  bWeight = 100;
                }
                if (a.name === "Common") {
                  aWeight = 100;
                }
                if (b.name === "Common") {
                  bWeight = 100;
                }
                if (a.name.indexOf("БКЗ") > -1) {
                  aWeight = 50;
                }
                if (b.name.indexOf("БКЗ") > -1) {
                  bWeight = 50;
                }
                return bWeight - aWeight;
              }),
            );
          }
          this.currentDateId = this.findNearestDate(schedules, halls[0].defaultHighlightedId);
          if (localStorage.getItem("agentIdFilter")) {
            this.setFilter(localStorage.getItem("agentIdFilter"));
          }
          this.ready = true;
        }
        this.checkSavedCurrentHall();
      });
    },
    closeNotification() {
      const storageItemName = this.user.email
        ? `warningClosed${this.user.id}`
        : "warningClosedGuest";
      localStorage.setItem(storageItemName, this.lastNotification.id);
      this.showWarning = false;
    },
    ...mapActions(schedule, {
      getScheduleByHalls: GET_SCHEDULE,
    }),
    ...mapMutations(schedule, {
      hideAgendaDetails: HIDE_AGENDA_EVENT_DETAILS,
      hideScheduleSpeaker: HIDE_SCHEDULE_SPEAKER,
    }),
    checkSavedCurrentHall() {
      const agentId = localStorage.getItem("agentId");
      if (agentId != null && this.halls.some(hall => hall.id === +agentId)) {
        this.setCurrentHall(+agentId);
      }
    },
    formatDate(date) {
      const { localeMoment } = getLocale(null, true);
      if (this.$moment.locale() !== localeMoment) {
        this.$moment.locale(localeMoment);
      }
      return this.$moment(date).format("DD MMMM");
    },
    /**
     * Скрываем информацию о спикере
     */
    resetSelectedSpeaker() {
      this.hideScheduleSpeaker();
    },
  },
};
</script>

<style lang="less" scoped>
.loader {
  justify-content: center;
}

.agenda-panel {
  flex-grow: 1;
}

.agenda {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  max-width: 980px;
  height: 100%;
  padding: 16px 8px;
  margin: 0 auto;
  overflow: auto;
  transform: translateZ(0);

  &__container {
    height: 100%;
  }

  &-warning {
    position: relative;
    padding: 5px 30px 5px 10px;
    margin-top: 10px;
    color: white;
    text-align: left;
    background: red;
    border-radius: 6px;

    &__icon {
      position: absolute;
      top: 6px;
      right: 10px;
      user-select: none;
    }
  }

  /* stylelint-disable */
  &-halls {
    width: 400px !important;
    margin: 0 auto 10px auto;
  }
  /* stylelint-enable */

  &-dates {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-content: center;
    color: var(--base-text-color);
  }

  &-date {
    display: inline-block;
    height: 24px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    color: var(--base-color);
    cursor: pointer;
    background: var(--base-button-active-color);
    border: 1px solid var(--base-color);
    border-radius: 10px;
    transition: all 0.3s;

    &--active {
      color: var(--base-button-active-color);
      background-color: var(--base-color);
      box-shadow: 0 2px 6px 0 var(--base-shadow-color);
    }

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  /* TODO при переезде на css modules цвет из переменных */
  &__back {
    padding: 10px 15px;
    border-bottom: 1px solid #ebebeb;
  }

  &__user-card {
    flex: 1 0 auto;
  }

  .clear_command_form {
    & > * {
      padding: 0;
      margin: 10px 0;
    }

    .clear_command_button {
      float: right;
      padding: 0 10px;
    }
  }
}
</style>
