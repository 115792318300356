<template>
  <ws-button
    :disabled="isDisabled"
    color="primary"
    :class="$style.btn"
    @click.stop="handleBtnClick"
  >
    {{ $t("Agenda.rateTeam") }}
  </ws-button>
</template>

<script>
import { mapState } from "vuex";
import { schedule } from "@/store/modules/store.namespaces";

import WsButton from "@/components/base/WsButton";

export default {
  components: {
    WsButton,
  },
  props: ["disabled", "eventId"],
  computed: {
    ...mapState(schedule, ["showRewardButton"]),
    isDisabled() {
      if (this.disabled) {
        return true;
      } // Оценка команды завершена

      return this.showRewardButton[this.eventId];
    },
  },
  methods: {
    handleBtnClick() {
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss" module>
.btn {
  width: 159px;
}
</style>
