<template>
  <flex-column-stack class="reward-form__user-list">
    <div v-for="user in users" :key="user.id" class="reward-form-participants">
      <div class="reward-form-participants__img">
        <img v-if="user.image" :src="user.image" />
        <span v-else class="icon-user user-icon"></span>
      </div>
      <p :title="user.name" class="reward-form-participants__name">{{ user.name }}</p>
    </div>
  </flex-column-stack>
</template>

<script>
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";

export default {
  components: {
    FlexColumnStack,
  },
  props: ["users"],
};
</script>

<style lang="less" scoped>
.user-icon {
  display: inline-block;
  text-align: center;
  line-height: 25px;
}
.reward-form__user-list {
  flex-grow: 0 !important;
  flex-shrink: 0;
  max-width: 200px;
  padding-top: 53px;
}
.reward-form-participants {
  display: flex;
  align-items: center;
  //   flex-basis: 40%;
  margin-bottom: 16px;
  max-height: 25px;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;

  &__img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: lightcyan;
    overflow: hidden;
    margin-right: 8px;
    text-align: center;
    & > img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }

  &__name {
    max-width: 165px;
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #000000;
  }
} // participants
</style>
